import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Event, NavigationStart } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth-core/auth.service';

@Component({
  selector: 'app-intranet',
  templateUrl: './intranet.component.html',
  styleUrls: ['./intranet.component.scss']
})
export class IntranetComponent implements OnInit {

  public hideSidebar: boolean;
  public minifySidebar: boolean;
  userAdmin:boolean = false;

  constructor(private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private authService: AuthService,
  ) {
    this.hideSidebar = false;
    this.minifySidebar = false;

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.hideSidebar = false;
        this.renderer.removeClass(this.document.body, 'page-sidebar-in');
      }
    });
  }

  ngOnInit() {
    const tokenParts = this.authService.accessToken.split('.');
    const payload = JSON.parse(atob(tokenParts[1]));
    this.userAdmin = payload['realm_access']['roles'].includes('user_manager');
  }

  toggleSidebar() {
    this.hideSidebar = !this.hideSidebar;
    if (this.hideSidebar) {
      this.renderer.addClass(this.document.body, 'page-sidebar-in');
    } else {
      this.renderer.removeClass(this.document.body, 'page-sidebar-in');
    }

  }
  toggleMinifySidebar() {
    this.minifySidebar = !this.minifySidebar;
    if (this.minifySidebar) {
      this.renderer.addClass(this.document.body, 'page-sidebar-mini');
    } else {
      this.renderer.removeClass(this.document.body, 'page-sidebar-mini');
    }
  }
}
