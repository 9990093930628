/**
 * Intranet Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EnquiryBrief { 
    title: string;
    description?: string;
    creation_ts?: string;
    owners?: Array<string>;
    parent?: any | null;
    is_active?: boolean;
    is_archived?: boolean;
    is_child?: boolean;
    _cls?: string;
    id?: any | null;
    readonly starts_on?: string;
    readonly u_is_participant?: boolean;
    readonly u_can_answer?: boolean;
    readonly u_has_answered?: boolean;
}

