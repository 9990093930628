<!--Page-->
<div class="page d-flex flex-row flex-column-fluid">

  <aside class="page-sidebar aside-dark bg-dark">
    <div class="h-100 flex-column d-flex" data-simplebar>

      <!--Aside-logo-->
      <div class="aside-logo p-3 position-relative">
        <a class="d-block">
          <div class="d-flex align-items-center flex-no-wrap text-truncate mt-5">
            <!--Sidebar-icon-->
            <div class="avatar lg sidebar-icon">
              <img src="/assets/logo_diables.jpg" class="rounded-circle img-fluid" alt="Diables de sant antoni">
            </div>
            <span class="sidebar-text px-1">
              <!--Sidebar-text-->
              <span class="sidebar-text text-truncate fs-4 fw-bolder">
                Diables de <br />Sant Antoni
              </span>
            </span>
          </div>
        </a>
      </div>
      <!--Aside-Menu-->
      <div class="aside-menu px-2 my-auto flex-column-fluid">
        <nav class="flex-grow-1 h-100" id="page-navbar">
          <!--:Sidebar nav-->
          <ul class="nav flex-column collapse-group collapse d-flex pt-4">

            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link d-flex align-items-center text-truncate" routerLink="">
                <span class="sidebar-icon">
                  <i class="bi bi-speedometer"></i>
                </span>
                <span class="sidebar-text">Dashboard</span>
              </a>
            </li>

            <li class="nav-item sidebar-title text-truncate opacity-25 small">
              <i class="bi bi-three-dots align-middle"></i>
              <span>La Colla</span>
            </li>
            <li class="nav-item">
              <a href="#users" data-bs-toggle="collapse" class="nav-link d-flex align-items-center text-truncate "
                aria-expanded="false">
                <span class="sidebar-icon">
                  <i class="bi bi-person fs-5 lh-1 align-middle"></i>
                </span>
                <!--Sidebar nav text-->
                <span class="sidebar-text">Usuaris</span>
              </a>
              <ul data-bs-target="#users" id="users" class="sidebar-dropdown list-unstyled collapse">
                <li class="sidebar-item" routerLinkActive="active">
                  <a class="sidebar-link" routerLink="/users/list">Llistat d'usuaris</a>
                </li>
                <li class="sidebar-item" routerLinkActive="active" *ngIf="userAdmin">
                  <a class="sidebar-link" routerLink="/users/new">Afegeix un usuari</a>
                </li>
              </ul>
            </li>

            <li class="nav-item sidebar-title text-truncate opacity-25 small">
              <i class="bi bi-three-dots align-middle"></i>
              <span>Activitats</span>
            </li>
            <li class="nav-item">
              <a href="#enquiries" data-bs-toggle="collapse" class="nav-link d-flex align-items-center text-truncate "
                aria-expanded="false">
                <span class="sidebar-icon">
                  <i class="bi bi-question-square fs-5 lh-1 align-middle"></i>
                </span>
                <!--Sidebar nav text-->
                <span class="sidebar-text">Doodles</span>
              </a>
              <ul data-bs-target="#enquiries" id="enquiries" class="sidebar-dropdown list-unstyled collapse">
                <li class="sidebar-item" routerLinkActive="active">
                  <a class="sidebar-link" routerLink="/enquiries/list/next">Propers doodles</a>
                </li>
                <li class="sidebar-item" routerLinkActive="active">
                  <a class="sidebar-link" routerLink="/enquiries/list/all">Tots els doodles</a>
                </li>
                <li class="sidebar-item" routerLinkActive="active">
                  <a class="sidebar-link" routerLink="/enquiries/new">Crea un nou doodle</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a href="#ballots" data-bs-toggle="collapse" class="nav-link d-flex align-items-center text-truncate "
                aria-expanded="false">
                <span class="sidebar-icon">
                  <i class="bi bi-check2-square fs-5 lh-1 align-middle"></i>
                </span>
                <!--Sidebar nav text-->
                <span class="sidebar-text">Votacions</span>
              </a>
              <ul data-bs-target="#ballots" id="ballots" class="sidebar-dropdown list-unstyled collapse">
                <li class="sidebar-item" routerLinkActive="active">
                  <a class="sidebar-link" routerLink="/ballots/list">Llistat de votacions</a>
                </li>
                <li class="sidebar-item" routerLinkActive="active">
                  <a class="sidebar-link" routerLink="/ballots/new">Crea una nova votació</a>
                </li>
              </ul>
            </li>



          </ul>
        </nav>
      </div>
    </div>
  </aside>
  <!--///////////Page Sidebar End///////////////-->

  <!--///Sidebar close button for 991px or below devices///-->
  <div class="sidebar-close d-lg-none" (click)="toggleSidebar()">
    <a href="#"></a>
  </div>
  <!--///.Sidebar close end///-->


  <!--///////////Page content wrapper///////////////-->
  <main class="page-content d-flex flex-column flex-row-fluid">

    <!--//page-header//-->
    <header class="navbar py-0 page-header navbar-expand navbar-light px-4">
      <a class="navbar-brand d-block d-lg-none" (click)="toggleSidebar()">
        <div class="d-flex align-items-center flex-no-wrap">
          <!--Sidebar-icon-->
          <div class="avatar sm ">
            <img src="/assets/logo_diables.jpg" class="rounded-circle img-fluid" alt="Diables de sant antoni">
          </div>
        </div>
      </a>
      <ul class="navbar-nav d-flex align-items-center h-100">


        <li class="nav-item d-none d-lg-flex flex-column h-100 me-lg-2 align-items-center justify-content-center">
          <a (click)="toggleMinifySidebar()" [class.active]="minifySidebar"
            class="sidebar-trigger hover-bg-primary nav-link rounded-circle size-35 d-flex align-items-center justify-content-center p-0">
            <i class="bi bi-arrow-left"></i>
          </a>
        </li>
        <li class="nav-item d-flex flex-column me-lg-2 h-100 justify-content-center dropdown">
          <a href="javascript:void(0)" data-bs-toggle="dropdown"
            class="d-flex align-items-center justify-content-center hover-bg-primary nav-link size-35 p-0 rounded-circle"
            data-bs-auto-close="outside" aria-expanded="false">
            <i class="bi bi-search"></i>
          </a>
          <!--Search dropdown menu-->
          <div class="dropdown-menu p-0 dropdown-menu-sm overflow-hidden mt-0">

            <!--Search form-->
            <form>
              <div class="d-flex align-items-center p-1 border-bottom ps-4">
                <div class="text-muted">
                  <i class="bi bi-search"></i>
                </div>
                <input type="text" autofocus class="form-control rounded-0 py-3 ps-2 border-0 shadow-none"
                  placeholder="Search everything...">
              </div>
            </form>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto d-flex align-items-center h-100">
        <app-topbar-notifications></app-topbar-notifications>
        <app-topbar-user></app-topbar-user>
      </ul>
    </header>
    <!--Main Header End-->
    <!--//Page content//-->
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <!--//Page content End//-->

    <!--//Page-footer//-->
    <footer class="pb-4">
      <div class="container-fluid px-4">
        <span class="d-block lh-sm small text-muted text-end">
          Foooter &copy;
        </span>
      </div>
    </footer>
    <!--/.Page Footer End-->

  </main>
  <!--///////////Page content wrapper End///////////////-->
</div>