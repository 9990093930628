/**
 * Intranet Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuditEntry { 
    author?: string;
    target_member?: string;
    operation?: string;
    timestamp?: string;
    object?: any | null;
    object_cls?: string;
    object_collection?: string;
    data?: any | null;
    id?: any | null;
}

