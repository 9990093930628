import { Component, OnInit } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { AuthService } from 'src/app/auth-core/auth.service';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/api';

@Component({
  selector: 'app-topbar-user',
  templateUrl: './topbar-user.component.html',
  styleUrls: ['./topbar-user.component.scss']
})
export class TopbarUserComponent {

  constructor(
    private authService: AuthService,
    private swPush: SwPush,
    private notifications: NotificationsService
    ) {}

  async subscribeToNotifications() {
    console.debug("Subscribing SwPush");
    try {
      const sub = await this.swPush.requestSubscription({
        serverPublicKey: environment.vapidPublicKey
      })
      this.notifications.notificationsWebpushPost(sub.toJSON()).subscribe();
      console.log("PushSubscription content:", sub.toJSON());
    } catch (err) {
      console.error("Could not subscribe to notifications due to:", err);
    }
  }

  logout() { this.authService.logout(); }
  get name() {
    if (this.authService.identityClaims) {
      return this.authService.identityClaims['nickname']; }
    else {
      return "";
    }
  }
  
  get email() {
    if (this.authService.identityClaims) {
       return this.authService.identityClaims['email'].trim().toLowerCase();
    } else {
      return "00000000000000000000000000000000";
    }
  }
}
