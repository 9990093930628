import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topbar-notifications',
  templateUrl: './topbar-notifications.component.html',
  styleUrls: ['./topbar-notifications.component.scss']
})
export class TopbarNotificationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
