/**
 * Intranet Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Poll { 
    title: string;
    description?: string;
    creation_ts?: string;
    owners?: Array<string>;
    participants?: Array<string>;
    parent?: any | null;
    is_active?: boolean;
    is_archived?: boolean;
    is_child?: boolean;
    id?: any | null;
    question: string;
    choices?: Array<string>;
    multianswer?: boolean;
    responses?: any | null;
}

