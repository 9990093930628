/**
 * Intranet Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InboxNotification { 
    author?: string | null;
    web_link?: string;
    message?: string;
    timestamp?: string;
    seen?: boolean;
    id?: any | null;
}

