
import { DateTime } from 'luxon';
import { environment } from 'src/environments/environment';


export function getTimestamp(time_string:string){
  return DateTime.fromISO(time_string, { locale: environment.locale, zone: environment.timezone });
}

export function friendly_time(start_time: string, end_time:string, full_day: boolean, show_remaining:boolean = false){
    var out_str = '';
    var start_timestamp = getTimestamp(start_time);
    var end_timestamp = getTimestamp(end_time);

    var same_day = start_timestamp.hasSame(end_timestamp, 'day');
    out_str += start_timestamp.toLocaleString(DateTime.DATE_HUGE);
    if (!full_day){
      out_str += " ";
      out_str += start_timestamp.toLocaleString(DateTime.TIME_24_SIMPLE);
    }
    if (!same_day || !full_day){
      out_str += " -"
      if (!same_day){
        out_str += " ";
        out_str += end_timestamp.toLocaleString(DateTime.DATE_HUGE);
      }
      if (!full_day){
        out_str += " ";
        out_str += end_timestamp.toLocaleString(DateTime.TIME_24_SIMPLE);
      }
    }
    if (show_remaining){
        out_str += " (";
        out_str += start_timestamp.toRelativeCalendar();
        out_str += ")"
    }
    out_str = out_str[0].toUpperCase() + out_str.slice(1);
    return out_str;

  }

  export function friendly_small_time(start_time: string, end_time:string, full_day: boolean, show_remaining:boolean = false){
    var out_str = '';
    var start_timestamp = DateTime.fromISO(start_time, { locale: environment.locale, sone: environment.timezone });
    var end_timestamp = DateTime.fromISO(end_time, { locale: environment.locale, sone: environment.timezone });

    var same_day = start_timestamp.hasSame(end_timestamp, 'day');
    out_str += start_timestamp.toLocaleString(DateTime.DATE_SHORT);
    if (!full_day){
      out_str += " ";
      out_str += start_timestamp.toLocaleString(DateTime.TIME_24_SIMPLE);
    }
    if (!same_day || !full_day){
      out_str += " -"
      if (!same_day){
        out_str += " ";
        out_str += end_timestamp.toLocaleString(DateTime.DATE_SHORT);
      }
      if (!full_day){
        out_str += " ";
        out_str += end_timestamp.toLocaleString(DateTime.TIME_24_SIMPLE);
      }
    }
    if (show_remaining){
        out_str += " (";
        out_str += start_timestamp.toRelativeCalendar();
        out_str += ")"
    }
    out_str = out_str[0].toUpperCase() + out_str.slice(1);
    return out_str;

  }