import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { ShouldLoginComponent } from './should-login.component';
import { AuthGuardWithForcedLogin } from './auth-core/auth-guard-with-forced-login.service';
import { IntranetComponent } from './page-layout-components/intranet/intranet.component';

// Only non-moduled routes here
const routes: Routes = [
  {
    path: '',
    redirectTo: 'index',
    pathMatch: 'full',
  },
  {
    path: '',
    component: IntranetComponent,
    canActivate: [AuthGuardWithForcedLogin],
    children: [
      {
        path: 'index',
        component: DashboardComponent,
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'enquiries',
        loadChildren: () => import('./enquiries/enquiries.module').then(m => m.EnquiriesModule)
      },
      {
        path: 'ballots',
        loadChildren: () => import('./ballots/ballots.module').then(m => m.BallotsModule)
      }
    ]
  },
  {
    path: 'benvinguda',
    canActivate: [AuthGuardWithForcedLogin],
    loadChildren: () => import('./start/start.module').then(m => m.StartModule)
  },
  {
    path: 'should-login',
    component: ShouldLoginComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', enableTracing: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
