/**
 * Intranet Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { EventResponseEntry } from '../model/eventResponseEntry';
// @ts-ignore
import { EventResponses } from '../model/eventResponses';
// @ts-ignore
import { PollResponseEntry } from '../model/pollResponseEntry';
// @ts-ignore
import { PollResponses } from '../model/pollResponses';
// @ts-ignore
import { SlottedEventResponseEntry } from '../model/slottedEventResponseEntry';
// @ts-ignore
import { SlottedEventResponses } from '../model/slottedEventResponses';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ResponsesService {

    protected basePath = 'http://api.localhost:5000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Remove a member response from an Event.
     * Note that special permissions/circumstances are required in order to change responses from other users (admin permissions or being the owner of the specific enquiry).
     * @param evId 
     * @param memberUuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesEventEvIdEntryDelete(evId: string, memberUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<any>;
    public responsesEventEvIdEntryDelete(evId: string, memberUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<any>>;
    public responsesEventEvIdEntryDelete(evId: string, memberUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<any>>;
    public responsesEventEvIdEntryDelete(evId: string, memberUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (evId === null || evId === undefined) {
            throw new Error('Required parameter evId was null or undefined when calling responsesEventEvIdEntryDelete.');
        }
        if (memberUuid === null || memberUuid === undefined) {
            throw new Error('Required parameter memberUuid was null or undefined when calling responsesEventEvIdEntryDelete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (memberUuid !== undefined && memberUuid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>memberUuid, 'member_uuid');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/event/${this.configuration.encodeParam({name: "evId", value: evId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/entry`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a user entry response for an Event.
     * @param evId 
     * @param memberUuid 
     * @param ifNoneMatch Tag to check against
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesEventEvIdEntryGet(evId: string, memberUuid: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<EventResponseEntry>;
    public responsesEventEvIdEntryGet(evId: string, memberUuid: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<EventResponseEntry>>;
    public responsesEventEvIdEntryGet(evId: string, memberUuid: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<EventResponseEntry>>;
    public responsesEventEvIdEntryGet(evId: string, memberUuid: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (evId === null || evId === undefined) {
            throw new Error('Required parameter evId was null or undefined when calling responsesEventEvIdEntryGet.');
        }
        if (memberUuid === null || memberUuid === undefined) {
            throw new Error('Required parameter memberUuid was null or undefined when calling responsesEventEvIdEntryGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (memberUuid !== undefined && memberUuid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>memberUuid, 'member_uuid');
        }

        let localVarHeaders = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            localVarHeaders = localVarHeaders.set('If-None-Match', String(ifNoneMatch));
        }

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/event/${this.configuration.encodeParam({name: "evId", value: evId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/entry`;
        return this.httpClient.request<EventResponseEntry>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch a member response on an Event.
     * Note that special permissions/circumstances are required in order to change responses from other users (admin permissions or being the owner of the specific enquiry).
     * @param evId 
     * @param eventResponseEntry 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesEventEvIdEntryPatch(evId: string, eventResponseEntry: EventResponseEntry, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<any>;
    public responsesEventEvIdEntryPatch(evId: string, eventResponseEntry: EventResponseEntry, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<any>>;
    public responsesEventEvIdEntryPatch(evId: string, eventResponseEntry: EventResponseEntry, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<any>>;
    public responsesEventEvIdEntryPatch(evId: string, eventResponseEntry: EventResponseEntry, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (evId === null || evId === undefined) {
            throw new Error('Required parameter evId was null or undefined when calling responsesEventEvIdEntryPatch.');
        }
        if (eventResponseEntry === null || eventResponseEntry === undefined) {
            throw new Error('Required parameter eventResponseEntry was null or undefined when calling responsesEventEvIdEntryPatch.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/event/${this.configuration.encodeParam({name: "evId", value: evId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/entry`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                body: eventResponseEntry,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set a member response on an Event.
     * Note that special permissions/circumstances are required in order to change responses from other users (admin permissions or being the owner of the specific enquiry).
     * @param evId 
     * @param eventResponseEntry 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesEventEvIdEntryPut(evId: string, eventResponseEntry: EventResponseEntry, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<any>;
    public responsesEventEvIdEntryPut(evId: string, eventResponseEntry: EventResponseEntry, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<any>>;
    public responsesEventEvIdEntryPut(evId: string, eventResponseEntry: EventResponseEntry, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<any>>;
    public responsesEventEvIdEntryPut(evId: string, eventResponseEntry: EventResponseEntry, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (evId === null || evId === undefined) {
            throw new Error('Required parameter evId was null or undefined when calling responsesEventEvIdEntryPut.');
        }
        if (eventResponseEntry === null || eventResponseEntry === undefined) {
            throw new Error('Required parameter eventResponseEntry was null or undefined when calling responsesEventEvIdEntryPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/event/${this.configuration.encodeParam({name: "evId", value: evId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/entry`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                body: eventResponseEntry,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get EventResponses structure by the Event id.
     * @param evId 
     * @param ifNoneMatch Tag to check against
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesEventEvIdGet(evId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<EventResponses>;
    public responsesEventEvIdGet(evId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<EventResponses>>;
    public responsesEventEvIdGet(evId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<EventResponses>>;
    public responsesEventEvIdGet(evId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (evId === null || evId === undefined) {
            throw new Error('Required parameter evId was null or undefined when calling responsesEventEvIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            localVarHeaders = localVarHeaders.set('If-None-Match', String(ifNoneMatch));
        }

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/event/${this.configuration.encodeParam({name: "evId", value: evId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<EventResponses>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export the responses to an enquiry on XLSX format.
     * For regular enquiries, a single sheet is returned with a list of members and their response to the Enquiry (exact format will depend on the enquiry type).  For an EnquiryGroup, a document with as many sheets as sub-enquiries is returned. The format for each sheet will match the non-group scenario.
     * @param enqId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesExportEnqIdGet(enqId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/json',}): Observable<Blob>;
    public responsesExportEnqIdGet(enqId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/json',}): Observable<HttpResponse<Blob>>;
    public responsesExportEnqIdGet(enqId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/json',}): Observable<HttpEvent<Blob>>;
    public responsesExportEnqIdGet(enqId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/json',}): Observable<any> {
        if (enqId === null || enqId === undefined) {
            throw new Error('Required parameter enqId was null or undefined when calling responsesExportEnqIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let localVarPath = `/responses/export/${this.configuration.encodeParam({name: "enqId", value: enqId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove a member response from a PollResponses.
     * Note that special permissions/circumstances are required in order to change responses from other users (admin permissions or being the owner of the specific enquiry).
     * @param pollId 
     * @param memberUuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesPollPollIdEntryDelete(pollId: string, memberUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<any>;
    public responsesPollPollIdEntryDelete(pollId: string, memberUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<any>>;
    public responsesPollPollIdEntryDelete(pollId: string, memberUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<any>>;
    public responsesPollPollIdEntryDelete(pollId: string, memberUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (pollId === null || pollId === undefined) {
            throw new Error('Required parameter pollId was null or undefined when calling responsesPollPollIdEntryDelete.');
        }
        if (memberUuid === null || memberUuid === undefined) {
            throw new Error('Required parameter memberUuid was null or undefined when calling responsesPollPollIdEntryDelete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (memberUuid !== undefined && memberUuid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>memberUuid, 'member_uuid');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/poll/${this.configuration.encodeParam({name: "pollId", value: pollId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/entry`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a user entry response for a Poll.
     * @param pollId 
     * @param memberUuid 
     * @param ifNoneMatch Tag to check against
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesPollPollIdEntryGet(pollId: string, memberUuid: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<PollResponseEntry>;
    public responsesPollPollIdEntryGet(pollId: string, memberUuid: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<PollResponseEntry>>;
    public responsesPollPollIdEntryGet(pollId: string, memberUuid: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<PollResponseEntry>>;
    public responsesPollPollIdEntryGet(pollId: string, memberUuid: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (pollId === null || pollId === undefined) {
            throw new Error('Required parameter pollId was null or undefined when calling responsesPollPollIdEntryGet.');
        }
        if (memberUuid === null || memberUuid === undefined) {
            throw new Error('Required parameter memberUuid was null or undefined when calling responsesPollPollIdEntryGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (memberUuid !== undefined && memberUuid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>memberUuid, 'member_uuid');
        }

        let localVarHeaders = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            localVarHeaders = localVarHeaders.set('If-None-Match', String(ifNoneMatch));
        }

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/poll/${this.configuration.encodeParam({name: "pollId", value: pollId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/entry`;
        return this.httpClient.request<PollResponseEntry>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch a member response on a Poll.
     * Note that special permissions/circumstances are required in order to change responses from other users (admin permissions or being the owner of the specific enquiry).
     * @param pollId 
     * @param pollResponseEntry 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesPollPollIdEntryPatch(pollId: string, pollResponseEntry: PollResponseEntry, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<any>;
    public responsesPollPollIdEntryPatch(pollId: string, pollResponseEntry: PollResponseEntry, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<any>>;
    public responsesPollPollIdEntryPatch(pollId: string, pollResponseEntry: PollResponseEntry, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<any>>;
    public responsesPollPollIdEntryPatch(pollId: string, pollResponseEntry: PollResponseEntry, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (pollId === null || pollId === undefined) {
            throw new Error('Required parameter pollId was null or undefined when calling responsesPollPollIdEntryPatch.');
        }
        if (pollResponseEntry === null || pollResponseEntry === undefined) {
            throw new Error('Required parameter pollResponseEntry was null or undefined when calling responsesPollPollIdEntryPatch.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/poll/${this.configuration.encodeParam({name: "pollId", value: pollId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/entry`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                body: pollResponseEntry,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set a member response on a Poll.
     * Note that special permissions/circumstances are required in order to change responses from other users (admin permissions or being the owner of the specific enquiry).
     * @param pollId 
     * @param pollResponseEntry 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesPollPollIdEntryPut(pollId: string, pollResponseEntry: PollResponseEntry, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<any>;
    public responsesPollPollIdEntryPut(pollId: string, pollResponseEntry: PollResponseEntry, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<any>>;
    public responsesPollPollIdEntryPut(pollId: string, pollResponseEntry: PollResponseEntry, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<any>>;
    public responsesPollPollIdEntryPut(pollId: string, pollResponseEntry: PollResponseEntry, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (pollId === null || pollId === undefined) {
            throw new Error('Required parameter pollId was null or undefined when calling responsesPollPollIdEntryPut.');
        }
        if (pollResponseEntry === null || pollResponseEntry === undefined) {
            throw new Error('Required parameter pollResponseEntry was null or undefined when calling responsesPollPollIdEntryPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/poll/${this.configuration.encodeParam({name: "pollId", value: pollId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/entry`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                body: pollResponseEntry,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get PollResponses structure by the Poll id.
     * @param pollId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesPollPollIdGet(pollId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<PollResponses>;
    public responsesPollPollIdGet(pollId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<PollResponses>>;
    public responsesPollPollIdGet(pollId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<PollResponses>>;
    public responsesPollPollIdGet(pollId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (pollId === null || pollId === undefined) {
            throw new Error('Required parameter pollId was null or undefined when calling responsesPollPollIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/poll/${this.configuration.encodeParam({name: "pollId", value: pollId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<PollResponses>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove a member response from a SlottedEvent.
     * Note that special permissions/circumstances are required in order to change responses from other users (admin permissions or being the owner of the specific enquiry).
     * @param sevId 
     * @param memberUuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesSlottedSevIdEntryDelete(sevId: string, memberUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<any>;
    public responsesSlottedSevIdEntryDelete(sevId: string, memberUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<any>>;
    public responsesSlottedSevIdEntryDelete(sevId: string, memberUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<any>>;
    public responsesSlottedSevIdEntryDelete(sevId: string, memberUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (sevId === null || sevId === undefined) {
            throw new Error('Required parameter sevId was null or undefined when calling responsesSlottedSevIdEntryDelete.');
        }
        if (memberUuid === null || memberUuid === undefined) {
            throw new Error('Required parameter memberUuid was null or undefined when calling responsesSlottedSevIdEntryDelete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (memberUuid !== undefined && memberUuid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>memberUuid, 'member_uuid');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/slotted/${this.configuration.encodeParam({name: "sevId", value: sevId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/entry`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a user entry response for a SlottedEvent.
     * @param sevId 
     * @param memberUuid 
     * @param ifNoneMatch Tag to check against
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesSlottedSevIdEntryGet(sevId: string, memberUuid: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<SlottedEventResponseEntry>;
    public responsesSlottedSevIdEntryGet(sevId: string, memberUuid: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<SlottedEventResponseEntry>>;
    public responsesSlottedSevIdEntryGet(sevId: string, memberUuid: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<SlottedEventResponseEntry>>;
    public responsesSlottedSevIdEntryGet(sevId: string, memberUuid: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (sevId === null || sevId === undefined) {
            throw new Error('Required parameter sevId was null or undefined when calling responsesSlottedSevIdEntryGet.');
        }
        if (memberUuid === null || memberUuid === undefined) {
            throw new Error('Required parameter memberUuid was null or undefined when calling responsesSlottedSevIdEntryGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (memberUuid !== undefined && memberUuid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>memberUuid, 'member_uuid');
        }

        let localVarHeaders = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            localVarHeaders = localVarHeaders.set('If-None-Match', String(ifNoneMatch));
        }

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/slotted/${this.configuration.encodeParam({name: "sevId", value: sevId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/entry`;
        return this.httpClient.request<SlottedEventResponseEntry>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch a member response on a SlottedEvent.
     * Note that special permissions/circumstances are required in order to change responses from other users (admin permissions or being the owner of the specific enquiry).
     * @param sevId 
     * @param slottedEventResponseEntry 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesSlottedSevIdEntryPatch(sevId: string, slottedEventResponseEntry: SlottedEventResponseEntry, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<any>;
    public responsesSlottedSevIdEntryPatch(sevId: string, slottedEventResponseEntry: SlottedEventResponseEntry, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<any>>;
    public responsesSlottedSevIdEntryPatch(sevId: string, slottedEventResponseEntry: SlottedEventResponseEntry, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<any>>;
    public responsesSlottedSevIdEntryPatch(sevId: string, slottedEventResponseEntry: SlottedEventResponseEntry, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (sevId === null || sevId === undefined) {
            throw new Error('Required parameter sevId was null or undefined when calling responsesSlottedSevIdEntryPatch.');
        }
        if (slottedEventResponseEntry === null || slottedEventResponseEntry === undefined) {
            throw new Error('Required parameter slottedEventResponseEntry was null or undefined when calling responsesSlottedSevIdEntryPatch.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/slotted/${this.configuration.encodeParam({name: "sevId", value: sevId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/entry`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                body: slottedEventResponseEntry,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set a member response on a SlottedEvent.
     * Note that special permissions/circumstances are required in order to change responses from other users (admin permissions or being the owner of the specific enquiry).
     * @param sevId 
     * @param slottedEventResponseEntry 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesSlottedSevIdEntryPut(sevId: string, slottedEventResponseEntry: SlottedEventResponseEntry, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<any>;
    public responsesSlottedSevIdEntryPut(sevId: string, slottedEventResponseEntry: SlottedEventResponseEntry, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<any>>;
    public responsesSlottedSevIdEntryPut(sevId: string, slottedEventResponseEntry: SlottedEventResponseEntry, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<any>>;
    public responsesSlottedSevIdEntryPut(sevId: string, slottedEventResponseEntry: SlottedEventResponseEntry, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (sevId === null || sevId === undefined) {
            throw new Error('Required parameter sevId was null or undefined when calling responsesSlottedSevIdEntryPut.');
        }
        if (slottedEventResponseEntry === null || slottedEventResponseEntry === undefined) {
            throw new Error('Required parameter slottedEventResponseEntry was null or undefined when calling responsesSlottedSevIdEntryPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/slotted/${this.configuration.encodeParam({name: "sevId", value: sevId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/entry`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                body: slottedEventResponseEntry,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get SlottedEventResponses structure by the SlottedEvent id.
     * @param sevId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public responsesSlottedSevIdGet(sevId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<SlottedEventResponses>;
    public responsesSlottedSevIdGet(sevId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<SlottedEventResponses>>;
    public responsesSlottedSevIdGet(sevId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<SlottedEventResponses>>;
    public responsesSlottedSevIdGet(sevId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (sevId === null || sevId === undefined) {
            throw new Error('Required parameter sevId was null or undefined when calling responsesSlottedSevIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak_oauth) required
        localVarCredential = this.configuration.lookupCredential('keycloak_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/responses/slotted/${this.configuration.encodeParam({name: "sevId", value: sevId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<SlottedEventResponses>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
