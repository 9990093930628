import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment, sentry_config } from './environments/environment';

if (environment.production) {
  enableProdMode();

  Sentry.init(sentry_config);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
