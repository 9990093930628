import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topbar-breadcrumbs',
  templateUrl: './topbar-breadcrumbs.component.html',
  styleUrls: ['./topbar-breadcrumbs.component.scss']
})
export class TopbarBreadcrumbsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
