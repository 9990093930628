<div class="card p-2">
  <div class="d-flex card-header justify-content-between">
    <h1 class="me-3 card-title mb-0">Propers actes</h1>
  </div>
  <div *ngIf="!eventGroups">
    <p>No hi ha res previst</p>
  </div>

  <div class="m-3">
    <div *ngFor="let yearGroup of eventGroups | keyvalue">
      <div *ngFor="let monthGroup of yearGroup.value | keyvalue">
        <h2>{{monthGroup.key | titlecase}} {{yearGroup.key}}</h2>
        <div *ngFor="let entry of monthGroup.value">
          <div class="d-flex">
            <a style="width: 90px; height: 90px;" [style.opacity]="entry.confirmed ? 1 : 0.5" class="p-1 m-1 bg-primary rounded"
              [routerLink]="['/enquiries/',entry.endpoint, entry.event.id, 'results']">
              <div style="text-align:center; color:rgba(255, 255, 255, 0.65)" class="text-small">{{entry.weekday |
                uppercase}}</div>
              <div style="text-align:center;">
                <h1 style=" color:rgba(255, 255, 255, 0.65)">{{entry.day}}</h1>
              </div>
            </a>
            <a class="mx-2 flex-fill" [routerLink]="['/enquiries/',entry.endpoint, entry.event.id, 'results']" [style.opacity]="entry.confirmed ? 1 : 0.5">
              <div>
                <div class="lead"> {{entry.event.title}}</div>
              </div>
              <div *ngIf="!entry.whole_day"><i class="bi bi-clock-history"></i>&nbsp;{{entry.start_time}} -
                {{entry.end_time}}</div>
              <div class="text-muted"> {{entry.event.description}}</div>
            </a>
            <div ngbDropdown class="d-inline-block" *ngIf="entry.organizer">
              <button class="btn btn-outline-danger" id="dropdownBasic2" ngbDropdownToggle>
                Administració
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                <button ngbDropdownItem class="btn btn-outline-secondary mx-1"
                  [routerLink]="['/enquiries/',entry.endpoint, entry.event.id, 'edit']">Edita</button>
                <button ngbDropdownItem class="btn btn-outline-secondary mx-1" *ngIf="entry.canArchive"
                  (click)="archive(entry)">Arxiva</button>
                <button ngbDropdownItem class="btn btn-outline-success mx-1" *ngIf="!entry.confirmed"
                  (click)="confirm(entry)">Confirma</button>
                <button ngbDropdownItem class="btn btn-outline-danger mx-1" (click)="cancel(entry)">Canceŀla</button>
              </div>
            </div>


            <div *ngIf="entry.canAnswer">
              <div class="btn btn-secondary mx-1"
                [routerLink]="['/enquiries/',entry.endpoint, entry.event.id, 'answer']">Respon</div>
            </div>




          </div>
        </div>
        <hr />
      </div>


    </div>
  </div>
</div>