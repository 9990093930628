<div class="card p-2 ">
  <div class="d-flex card-header justify-content-between">
    <h1 class="me-3 card-title mb-0">Doodles pendents</h1>
  </div>
  <div *ngIf="enquiries && enquiries.length > 0">
    <hr />
    <div *ngFor="let enquiry of enquiries">
      <div class="d-flex">
        <div class="px-2 align-self-center" [ngClass]="enquiry.color">
          <div style="font-size: 1.5rem"><i class="bi fa-sm" [ngClass]="enquiry.icon"></i></div>
        </div>
        <div class="px-2 align-self-center" style="width:80px"> <span *ngIf="enquiry.starts_on">{{enquiry.starts_on | date:
            'dd/MM/yy'}}</span>
        </div>
        <div class="px-2 flex-fill mr-auto align-self-center">
          <a>{{enquiry.title}}</a>
        </div>
        <div class="px-2">
          <a type="button" class="btn btn-primary" routerLink="../enquiries/{{enquiry.type}}/{{enquiry.id}}/answer">
            Respon
          </a>

        </div>
      </div>
      <hr />
    </div>
    <div *ngIf="canGetMoreEnquiries" class="p-2">
      <div class="btn btn-secondary" (click)="getMoreEnquiries()">Carrega més doodles</div>
    </div>
  </div>
  <div *ngIf="enquiries && enquiries.length == 0" class="m-4 ">
    <h3>Feilictats! No tens doodles pendents</h3>
  </div>
</div>