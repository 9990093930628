import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { AuthService } from './auth-core/auth.service';
import { Event, NavigationStart, Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'intranet-frontend';

  constructor(private authService: AuthService,
  ) {
    this.authService.runInitialLoginSequence();
  }

  ngOnInit() {
  }
}
