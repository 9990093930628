export * from './agents.service';
import { AgentsService } from './agents.service';
export * from './audit.service';
import { AuditService } from './audit.service';
export * from './ballots.service';
import { BallotsService } from './ballots.service';
export * from './enquiries.service';
import { EnquiriesService } from './enquiries.service';
export * from './health.service';
import { HealthService } from './health.service';
export * from './notifications.service';
import { NotificationsService } from './notifications.service';
export * from './responses.service';
import { ResponsesService } from './responses.service';
export const APIS = [AgentsService, AuditService, BallotsService, EnquiriesService, HealthService, NotificationsService, ResponsesService];
