import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { version } from "./version";

export const environment = {
  production: true,
  vapidPublicKey: "BH83MKwfihfqxwqw8C5r_S0VqqTTiXkMBMYZHmC2CeUhWx8f2SLvgBI0RM-9EW46ygW_G8eEwMBhr1_8HapZ4b0",
  keycloakUrl: 'https://keycloak.stage.diablesdesantantoni.cat/realms/ddsa',
  apiBasePath: 'https://api.stage.diablesdesantantoni.cat',
  timezone: "Europe/Madrid",
  locale: "ca"
};

export const sentry_config = {
  dsn: "https://ce4a1d7e096842c88431ca4e694c0006@o201760.ingest.sentry.io/4504321847656448",
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ["https://api.stage.diablesdesantantoni.cat"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  environment: "stage",
  release: version.id,
    
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
}