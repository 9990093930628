import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardPendingEnquiriesComponent } from './dashboard-pending-enquiries/dashboard-pending-enquiries.component';
import { DashboardNextEventsComponent } from './dashboard-next-events/dashboard-next-events.component';
import { RouterModule } from '@angular/router';
import { DashboardCalendarComponent } from './dashboard-calendar/dashboard-calendar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardPendingEnquiriesComponent, 
    DashboardNextEventsComponent, DashboardCalendarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule
  ]
})
export class DashboardModule { }
