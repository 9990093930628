import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { ApiModule, BASE_PATH } from './api';
import { HttpClientModule } from '@angular/common/http';
import { TopbarNotificationsComponent } from './page-layout-components/topbar-notifications/topbar-notifications.component';
import { TopbarUserComponent } from './page-layout-components/topbar-user/topbar-user.component';
import { TopbarBreadcrumbsComponent } from './page-layout-components/topbar-breadcrumbs/topbar-breadcrumbs.component';
import { CoreModule } from './auth-core/core.module';
import { ShouldLoginComponent } from './should-login.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BlankComponent } from './page-layout-components/blank/blank.component';
import { IntranetComponent } from './page-layout-components/intranet/intranet.component';
import { GravatarModule } from 'ngx-gravatar';
// import { FullCalendarModule } from '@fullcalendar/angular';


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    TopbarNotificationsComponent,
    TopbarUserComponent,
    TopbarBreadcrumbsComponent,
    TopbarNotificationsComponent,
    TopbarUserComponent,
    TopbarBreadcrumbsComponent,
    ShouldLoginComponent,
    BlankComponent,
    IntranetComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule.forRoot(),
    // FullCalendarModule,
    HttpClientModule,
    ApiModule,
    DashboardModule,
    GravatarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }), 
    AppRoutingModule, ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the application is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
}) // Needs to be last, wildcard 404 catch-all is here
  ],
  providers: [{ provide: BASE_PATH, useValue: environment.apiBasePath }],
  bootstrap: [AppComponent]
})
export class AppModule { }
