import { Component, OnInit } from '@angular/core';
import { EnquiriesService } from 'src/app/api';


export const ENQUIRYTYPE_MAP = {
  'Enquiry.Event': 'event',
  'Enquiry.Poll': 'poll',
  'Enquiry.SlottedEvent': 'slottedEvent',
  'Enquiry.EnquiryGroup': 'enquiryGroup',
}
export const ENQUIRYTYPE_MAP_ICONS = {
  'Enquiry.Event': 'bi-calendar-event',
  'Enquiry.Poll': 'bi-question-square',
  'Enquiry.SlottedEvent': 'bi-calendar-plus',
  'Enquiry.EnquiryGroup': 'bi-plus-square',
}

export const ENQUIRYTYPE_MAP_COLORS = {
  'Enquiry.Event': 'text-primary',
  'Enquiry.Poll': 'text-success',
  'Enquiry.SlottedEvent': 'text-warning',
  'Enquiry.EnquiryGroup': 'text-danger',
}


@Component({
  selector: 'app-dashboard-pending-enquiries',
  templateUrl: './dashboard-pending-enquiries.component.html',
  styleUrls: ['./dashboard-pending-enquiries.component.scss']
})
export class DashboardPendingEnquiriesComponent implements OnInit {

  constructor(private es: EnquiriesService) { }

  enquiries: any;
  page_size = 3;
  pagination = {
    "total": undefined,
    "total_pages": undefined,
    "first_page": undefined, "last_page": undefined, "page": 1
  }
  canGetMoreEnquiries = false;

  ngOnInit() {
    this.enquiries = [];
    this.pagination['page'] = 1;
    this.enquiries = [];
    this.getEnquiries();
  }

  getEnquiries() {
    var isArchived = false;
    var includeChildren = true;
    var isActive = true;
    var uIsParticipant = null;
    var uCanAnswer = true;
    var uHasAnswered = false;
    var search = null;
    this.es.enquiriesGet(includeChildren, isActive, isArchived, search, uIsParticipant, uCanAnswer, uHasAnswered,
      this.pagination['page'], this.page_size, 'response').subscribe((response) => {
        this.pagination = JSON.parse(response.headers.get('x-pagination'));
        this.canGetMoreEnquiries = (this.pagination['page'] < this.pagination['last_page'])
        for (var e of response.body) {
          e['type'] = ENQUIRYTYPE_MAP[e._cls];
          e['icon'] = ENQUIRYTYPE_MAP_ICONS[e._cls]
          e['color'] = ENQUIRYTYPE_MAP_COLORS[e._cls]
          this.enquiries.push(e);
        }
      });
  }

  getMoreEnquiries() {
    if (this.pagination['page'] < this.pagination['last_page']) {
      this.pagination.page += 1;
      this.getEnquiries();
    }
  }

}
