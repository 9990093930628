<main>
    <!--page-hero-->
    <section class=" position-relative">
        <div class="container pt-11 pt-lg-14 pb-9 pb-lg-11 position-relative z-index-1">
            <div class="row">

                <router-outlet></router-outlet>
            </div>
        </div>
    </section>
</main>