<li class="nav-item dropdown d-flex align-items-center justify-content-center flex-column h-100">
  <a href="#"
    class="nav-link dropdown-toggle rounded-pill p-1 lh-1 pe-1 pe-md-2 hover-bg-primary d-flex align-items-center justify-content-center"
    aria-expanded="false" data-bs-toggle="dropdown" data-bs-auto-close="outside">
    <div class="d-flex align-items-center">

      <!--Avatar with status-->
      <div class="avatar-status status-online me-sm-2 avatar xs">
        <img ngxGravatar [email]="email" size="24" class="avatar sm rounded-circle img-fluid"
          alt="{{ name }}" />
      </div>
      <span class="d-none d-md-inline-block">{{ name }}</span>
    </div>
  </a>

  <div class="dropdown-menu mt-0 pt-0 dropdown-menu-end overflow-hidden">
    <!--User meta-->
    <div class="position-relative overflow-hidden p-3 mb-3 border-bottom">
      <h5 class="mb-1">{{ name }}</h5>
      <p class="text-muted small mb-0 lh-1">Diable</p>
    </div>
    <a routerLink="users/me" class="dropdown-item"> <i class="bi bi-person me-2"></i> Perfil</a>
    <a (click)="subscribeToNotifications()" class="dropdown-item btn"><i class="bi bi-chat-left-text me-2"></i> Activa
      notificacions</a>
    <a class="dropdown-item btn"> <i class="bi bi-gear me-2"></i> Configuració</a>
    <hr class="mt-3 mb-1">
    <a (click)="logout()" class="dropdown-item d-flex align-items-center">
      <i class="bi bi-box-arrow-left me-2"></i>
      Desconnecta
    </a>
  </div>
</li>