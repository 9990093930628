import { Component, OnInit } from '@angular/core';
import { EnquiriesService, Event, EventPatch, SlottedEvent, SlottedEventPatch } from 'src/app/api';
import { AuthService } from 'src/app/auth-core/auth.service';
import { friendly_time, friendly_small_time, getTimestamp } from 'src/app/enquiries/common/date_format';
import { DateTime } from 'luxon';
import { s } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-dashboard-next-events',
  templateUrl: './dashboard-next-events.component.html',
  styleUrls: ['./dashboard-next-events.component.scss']
})
export class DashboardNextEventsComponent implements OnInit {


  eventGroups: { [year: number]: { [month: number]: any } };
  eventData: any;

  isActive = undefined;
  isArchived = false;

  constructor(private es: EnquiriesService, private authService: AuthService) {
  }
  ngOnInit() {
    this.reload();
  }

  reload() {
    this.eventGroups = {};
    this.eventData = [];
    this.getEvents();
    this.getSlottedEvents();
  }

  getEvents() {

    this.es.enquiriesEventGet(undefined, this.isActive, this.isArchived).subscribe((response) => {
      var events = response;
      for (let e of events) {
        if (e.status == 2) {
          continue;
        }
        var start_timestamp = getTimestamp(e.start_date);
        var end_timestamp = getTimestamp(e.end_date);

        var different_end_day = start_timestamp.toLocaleString({ 'day': '2-digit' }) != end_timestamp.toLocaleString({ 'day': '2-digit' });
        var end_time = end_timestamp.toLocaleString(DateTime.TIME_24_SIMPLE)
        if (different_end_day) {
          end_time = end_timestamp.toLocaleString({ 'weekday': 'short', 'day': '2-digit' }) + " " + end_time;
        }
        this.eventData.push({
          year: start_timestamp.year,
          month: start_timestamp.toLocaleString({ 'month': 'long' }),
          day: start_timestamp.toLocaleString({ 'day': '2-digit' }),
          weekday: start_timestamp.toLocaleString({ 'weekday': 'long' }),
          start_time: start_timestamp.toLocaleString(DateTime.TIME_24_SIMPLE),
          end_time: end_time,
          endpoint: 'event',
          whole_day: e.whole_day,
          event: e,
          organizer: this.checkIfOrganizer(e.owners),
          canAnswer: e.is_active && e.participants.includes(this.authService.identityClaims['sub']),
          canArchive: end_timestamp < DateTime.now(),
          confirmed: e.status == 1
        });

      }
      this.computeGroups();
    });
  }

  getSlottedEvents() {
    this.es.enquiriesSlottedGet(undefined, this.isActive, this.isArchived).subscribe((response) => {
      var slottedEvents = response;
      for (let e of slottedEvents) {
        var canArchive = true;
        var groupedSlots = {}
        for (var i = 0; i < e.slots.length; i++) {
          if (e.slots[i].status == 2) {
            continue;
          }
          var start_timestamp = getTimestamp(e.slots[i].start_date);
          var start_date = start_timestamp.toLocaleString(DateTime.DATE_SHORT)
          groupedSlots[start_date] = groupedSlots[start_date] || [];
          groupedSlots[start_date].push({ idx: i, slot: e.slots[i] });
          if (getTimestamp(e.slots[i].end_date) > DateTime.now()) {
            canArchive = false;
          }
        }

        for (let d in groupedSlots) {
          var start_timestamp = undefined;
          var end_timestamp = undefined;

          var whole_day = false;
          var status = 0;
          var slotIdxes = [];
          for (let g of groupedSlots[d]) {
            var s = g.slot;
            slotIdxes.push(g.idx)
            status += s.status
            whole_day = whole_day || s.whole_day;
            start_timestamp = start_timestamp || getTimestamp(s.start_date);
            end_timestamp = end_timestamp || getTimestamp(s.end_date);
            if (getTimestamp(s.start_date) < start_timestamp) {
              start_timestamp = getTimestamp(s.start_date);
            }
            if (getTimestamp(s.end_date) > end_timestamp) {
              end_timestamp = getTimestamp(s.end_date);
            }
          }
          if (status > 0) {
            status = 1;
          }
          var different_end_day = start_timestamp.toLocaleString({ 'day': '2-digit' }) != end_timestamp.toLocaleString({ 'day': '2-digit' });
          var end_time = end_timestamp.toLocaleString(DateTime.TIME_24_SIMPLE)
          if (different_end_day) {
            end_time = end_timestamp.toLocaleString({ 'weekday': 'short', 'day': '2-digit' }) + " " + end_time;
          }
          this.eventData.push({
            year: start_timestamp.year,
            month: start_timestamp.toLocaleString({ 'month': 'long' }),
            day: start_timestamp.toLocaleString({ 'day': '2-digit' }),
            weekday: start_timestamp.toLocaleString({ 'weekday': 'long' }),
            start_time: start_timestamp.toLocaleString(DateTime.TIME_24_SIMPLE),
            end_time: end_time,
            endpoint: 'slottedEvent',
            whole_day: whole_day,
            event: e,
            organizer: this.checkIfOrganizer(e.owners),
            canAnswer: e.is_active && e.participants.includes(this.authService.identityClaims['sub']),
            canArchive: canArchive,
            slotIdxes: slotIdxes,
            confirmed: status == 1
          })
        }
      }
      this.computeGroups();
    });
  }

  computeGroups() {
    this.eventGroups = {};
    var yearEvents = this.groupBy(this.eventData, 'year');
    for (let year in yearEvents) {
      console.log(yearEvents[year]);
      this.eventGroups[year] = {};
      var monthEvents = this.groupBy(yearEvents[year], 'month');
      console.log(monthEvents);
      for (let month in monthEvents) {
        this.eventGroups[year][month] = [];
        var toSortMonthEvents = monthEvents[month];
        toSortMonthEvents.sort((a, b) => { return a.day - b.day })
        for (let e of monthEvents[month]) {
          this.eventGroups[year][month].push(e);
        }
      }
    }
  }

  groupBy(list, property) {
    return list.reduce((groups, item) => {
      const val = item[property];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
    }, {});
  }

  friendly_time(start_time: string, end_time: string, full_day: boolean) {
    var out_str = '';
    var start_timestamp = getTimestamp(start_time);
    var end_timestamp = getTimestamp(end_time);

    var same_day = start_timestamp.hasSame(end_timestamp, 'day');
    out_str += start_timestamp.toLocaleString({ weekday: 'long', day: '2-digit' });
    if (!full_day) {
      out_str += " ";
      out_str += start_timestamp.toLocaleString(DateTime.TIME_24_SIMPLE);
    }
    if (!same_day || !full_day) {
      out_str += " -"
      if (!same_day) {
        out_str += " ";
        var et = end_timestamp.toLocaleString({ weekday: 'long', day: '2-digit' });
        out_str += et[0].toUpperCase() + et.slice(1);
      }
      if (!full_day) {
        out_str += " ";
        out_str += end_timestamp.toLocaleString(DateTime.TIME_24_SIMPLE);
      }
    }
    out_str = out_str[0].toUpperCase() + out_str.slice(1);
    return out_str;

  }

  checkIfOrganizer(owners: string[]) {
    const tokenParts = this.authService.accessToken.split('.');
    const payload = JSON.parse(atob(tokenParts[1]));
    if (payload['realm_access']['roles'].includes('enquiry_manager')) {
      return true;
    }
    // Check that the user is an owner
    if (owners.includes(this.authService.identityClaims['sub'])) {
      return true;
    }
    return false;
  }


  changeStatus(entry, status, reload=true) {
    var retval = false;
    if (entry.endpoint == 'event') {
      var epatch: EventPatch = {
        id: entry.event.id,
        status: status,
      }
      this.es.enquiriesEventPatch(epatch).subscribe(
        success => {
          if (reload) {
            this.reload();
          }
        },
        error => {
          if (reload) {
            this.reload();
          }
        });
      retval = status == 2;
    } else {
      var slots = entry.event.slots;
      for (let es of entry.slotIdxes) {
        slots[es].status = status;
      }
      var sepatch: SlottedEventPatch = {
        id: entry.event.id,
        slots: slots,
      }
      this.es.enquiriesSlottedPatch(sepatch).subscribe(
        success => {
          if (reload) {
            this.reload();
          }
        },
        error => {
          if (reload) {
            this.reload();
          }
        });
      retval = true;
      for (let s of slots) {
        if (!(s.status == 2)) {
          retval = false;
        }
      }
    }
    return retval;
  }



  confirm(entry) {
    this.changeStatus(entry, 1);
  }


  cancel(entry) {
    if (!confirm(`Estàs segur que vols canceŀlar ${entry.event.title}? També s'arxivarà l'acte`)) {
      return;
    }
    if (this.changeStatus(entry, 2, false)) {
      this.archive(entry, false);
    }
  }

  archive(entry, requireConfirm = true) {
    if (requireConfirm) {
      if (!confirm(`Estàs segur que vols arxivar ${entry.event.title}?`)) {
        return;
      }
    }
    var patch = {
      id: entry.event.id,
      is_archived: true,
      is_active: false
    }

    if (entry.endpoint == 'event') {
      this.es.enquiriesEventPatch(patch).subscribe(
        success => {
          this.reload();
        },
        error => {
          this.reload();
        });
    } else {
      this.es.enquiriesSlottedPatch(patch).subscribe(
        success => {
          this.reload();
        },  
        error => {
          this.reload();
        });
    }
  }
}

